.boxRow {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  box-sizing: border-box; 
  border-radius: 5px;
  background: rgba($color: #000000, $alpha: 0.6);
  box-shadow: 0px 0px 10px rgba($color: #00ccff, $alpha: 0.4);
  margin: 0 0 25px;

}

.textBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  transition-duration: .3s; 
}

.defaultArrow, .inverseArrow {
  position: absolute;
  right: 20px;
  top: 15px;
  transition-duration: .3s;
}

.defaultArrow {
  transform: rotateX(180deg);
}

.iconArrow {
  width: 23px;
}

.title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: #ffffff;
  font-size: 27px;
  padding-right: 30px;
}


.textPadding {
  padding: 10px 0;
}

.text {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  color: #ffffff;
  font-size: 20px;
  animation-name: fadeIn;
  animation-duration: .5s;

  @keyframes fadeIn {
    0%{
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
  }
  br {
    display: block;
    margin: 6px;
    content: "";
  }
}

@media screen and (max-width: 992px) {

  .boxRow {
    margin: 0 0 20px;
  
  }
  
  .defaultArrow, .inverseArrow {
    right: 10px;
    top: 8px;
  }
    
  .iconArrow {
    width: 15px;
  }
  
  .title {
    font-size: 16px;
    padding-right: 20px;
  }

  .text {
    font-size: 16px;
  }
}
.main {
  width: 100%;
  height: 100%;
  background:  linear-gradient(to bottom, rgba($color: #000000, $alpha: 1) 0%, rgba($color: #330000, $alpha: 1) 100%);
}

.wrap{
  position: relative;
  width: 100%;
  height: 100%;
  background:
  repeating-linear-gradient(
    rgba($color: #ffffff, $alpha: 0.05) 0px,
    rgba($color: #ffffff, $alpha: 0.05) 1px,
    rgba($color: #ffffff, $alpha: 0) 1px,
    rgba($color: #ffffff, $alpha: 0) 5px);
}

.mediaWrap {
  position: relative;
  overflow: hidden;
}

.backgroundMedia {
  position: absolute;
  width: 100%;
  height: 1000px;
  background-image: url("../../images/Backgrounds/background_media.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}


.mediaTextBg {
  position: absolute;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #ffffff;
  font-size: 161px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.16;
  text-transform: uppercase;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.contentWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.assetWrap {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.titleWrap {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 40px;
}

.underline {
  margin: auto;
  width: 280px;
  height: 10px;
  background-image: url( "../../images/Images/line_titles.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.title {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #ffffff;
  font-size: 55px;
}

.droneTop {
  position: absolute;
  top: 400px;
  left: calc(50% - 1200px);
}

.droneMid {
  top: -80px;
  position: absolute;
  right: calc(50% - 1200px);
}

.droneBottom {
  position: absolute;
  bottom: 0px;
  left: calc(50% - 1200px);
}

.drone1 {
  animation-name: droneMove;
  animation-duration: 20s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  
  @keyframes droneMove {
    0%   {transform: translate(0px, 0px);}
    20%  {transform: translate(20px, 20px);}
    40%  {transform: translate(-30px, 0);}
    50%  {transform: translate(0, -20px);}
    75%  {transform: translate(20px, -10px);}
    0% {transform: translate(0, 0);}
  }
}

.MediaCarousel {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: .7);
  z-index: 1;
}

.MediaCarouselWrap {
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
}

.closeButton {
  position: absolute;
  right: calc(50% - 820px);
  color: #ffffff;
  font-size: 40px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin-top: 85px;
  z-index: 1;
  user-select: none;
  cursor: pointer;
}

@media screen and (max-width: 1715px)  {

  .closeButton {
    position: absolute;
    right: 35px;
    color: #ffffff;
    font-size: 40px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    margin-top: 85px;
    z-index: 1;
    user-select: none;
    cursor: pointer;
  }
}

@media screen and (max-width: 1100px)  {

  .droneTop, .droneMid, .droneBottom {
    display: none;
  }
}  

@media screen and (max-width: 992px) {

  .MediaCarousel {
    display: none;
  }

  .contentWrap {
    margin-bottom: 10px;
  }

  .titleWrap {
    margin-bottom: 20px;
  }
    
  .title {
    font-size: 30px;
  }

}

@media screen and (max-width: 720px) {

.mediaWrap {
  width: 100%;
  height: 1000px;
  max-height: calc(100vw * 4 / 3);
}

.backgroundMedia {
  position: absolute;
  width: 100%;
  height: 1000px;
  max-height: calc(90vw * 4 / 3);
  background-image: url("../../images/Backgrounds/background_media.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.videoPlayer {
  position: relative;
  width: 100%;
  height: 1000px;
  max-height: calc(70vw * 4 / 3);
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.container {
  margin: -40% auto 0;
}
.mediaTextBg {
  position: absolute;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #ffffff;
  font-size: 80px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.16;
  text-transform: uppercase;
}
}
.main {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left:0;
  width: 100%;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.5);
  z-index: 6;
}

.wrap {
  position: relative;
  width: 790px;
  height: 342px;
  border-radius: 20px;
  border: 1px solid rgba($color: #0d819d, $alpha: 1.0);
  background: linear-gradient(to bottom, rgba($color: #000000, $alpha: 1) 0%, rgba($color: #004759, $alpha: 1) 100%);
}
.inputWrap {
  z-index: 5;

}

.linearBg {
  width: 100%;
  height: 100%;
  background:  repeating-linear-gradient(
    rgba($color: #ffffff, $alpha: 0.05) 0px,
    rgba($color: #ffffff, $alpha: 0.05) 1px,
    rgba($color: #ffffff, $alpha: 0) 1px,
    rgba($color: #ffffff, $alpha: 0) 5px);
}

.logo {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url("../../images/Logos/logoGrayscaleNewsletter.png") no-repeat center;
}

.closeBtn {
  position: absolute;
  width: 17px;
  height: 17px;
  top: 16px;
  right: 17px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #ffffff;
  font-size: 24px;
  cursor: pointer;
}

.textWrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}

.title {
  font-family: 'Russo One', sans-serif;
  color: #ffffff;
  font-size: 34px;
}

.emailBg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 600px;
  height: 45px;
  border-radius: 4px;
  border: 1px solid rgba($color: #0d819d, $alpha: 0.5);
  background: rgba($color: #000000, $alpha: 0.7);
  margin-bottom: 20px;
}

.email {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 24px;
  width: 99%;
  height: 90%;
  border-radius: 4px;
  outline: none;
  border: none;
  padding: 0 8px
}

.termText {
  font-family: 'Montserrat', sans-serif;
  font-style: italic;
  color: #ffffff;
  font-size: 18px;
}

.termTextBlue {
  font-family: 'Montserrat', sans-serif;
  font-style: italic;
  color: #00ccff;
  font-size: 18px;
}

.suscribeBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 205px;
  height: 50px;
  background: #00ccff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
  z-index: 4;
}


@media screen and (max-width: 992px) {
  .main {
    box-sizing: border-box;
    padding: 0 20px;
  }

  .wrap {
    width: 100%;
    height: 250px;
    box-sizing: border-box;
    border-radius: 8px;
  }
    
  .closeBtn {
    top: 2px;
    right: 5px;
    font-size: 22px;
  }
  
  .textWrap {
    width: 100%;
    height: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    text-align: center;
  }
  
  .title {
    font-size: 20px;
  }
  
  .emailBg {
    width: 100%;
    height: 30px;
    margin-bottom: 20px;
  }
  
  .email {
    font-size: 16px;
  }
  
  .termText {
    font-size: 12px;
  }
  
  .termTextBlue {
    font-size: 12px;
  }
  
  .suscribeBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    width: 205px;
    height: 50px;
    background: #00ccff;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: #ffffff;
    font-size: 20px;
    cursor: pointer;
    z-index: 4;
  }

}
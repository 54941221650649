.main {
  width: 100%;
  height: calc(100vh - 325px);
  min-height: 600px;
}

.wrap{
  position: relative;
  width: 100%;
  height: 100%;
  background:
  repeating-linear-gradient(
    rgba($color: #ffffff, $alpha: 0.05) 0px,
    rgba($color: #ffffff, $alpha: 0.05) 1px,
    rgba($color: #ffffff, $alpha: 0) 1px,
    rgba($color: #ffffff, $alpha: 0) 5px),
    linear-gradient(to bottom, rgba($color: #000000, $alpha: 1) 0%, rgba($color: #373737, $alpha: 1) 100%);
}

.logoBg {
  position: absolute;
  width: 100%;
  height: 800px;
  background: url("../../images/Logos/bg_logoGrayscale.png") no-repeat center;
  background-size: contain;  
}

.container {
  margin: auto;
  height: 100%;
  display: flex;
  max-width: 1650px;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 20px;
}

.title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #ffffff;
  font-size: 55px;
  margin: 50px 0 20px;
}

.textBox {
  width: 100%;
  max-width: 1200px;
  height: auto;
  padding: 20px 40px;
  box-sizing: border-box;
  overflow-y: scroll;
  
  
  &::-webkit-scrollbar {
    margin-right: 4px;
    width: 4px;
  }
  &::-webkit-scrollbar-track {
      background: transparent;
  }
  &::-webkit-scrollbar-thumb {
      background: #ffffff;
  }
  &::-webkit-scrollbar-thumb:hover {
      background: #d3d3d3;
  }
}

@media screen and (max-width: 1100px)  {

  .main {
    height: calc(100vh - 305px);
  }
}
  
@media screen and (max-width: 992px) {
  .main {
    height: auto;
    min-height: calc(100vh - 305px);
  }

  .wrap{
    min-height: calc(100vh - 305px);
  }

  .logoBg {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("../../images/Logos/bg_logoGrayscale.png") no-repeat;
    background-position: 50% 50px;
    background-size: 500px;
  }
  
  .title {
    font-size: 33px;
    margin: 10px 0 10px;
  }

  .textBox {
    width: 100%;
    max-width: 1200px;
    height: auto;
    padding: 10px 5px;
    box-sizing: border-box;
    overflow-y: scroll;
  }
}
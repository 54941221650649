.main {
  width: 100%;
  height: calc(100vh - 85px);
  background-image: url("../../images/Backgrounds/bgmainopror.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
  
.wrap{
  margin: auto;
  position: relative;
  display: flex;
  max-width: 1650px;
  height: 100%;
}

.oprorLogo {
  width: 1000px;
  height: 100%;
  background: url("../../images/Logos/logo_opror.png") no-repeat center;
  background-size: 120%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.videoPlayer {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.column {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 20px;
  z-index: 2;
}

.textWrap {
  min-height: 400px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  user-select: none;
}

.title {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #ffffff;
  font-size: 55px;
}

.text {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  font-weight: 400;
  color: #ffffff;
  font-size: 22px;
  margin: 20px 0;
}

.btnShadow {
  filter: drop-shadow( 0px 6px rgba($color: #000000, $alpha: 0.6));
  &:active {
    filter: drop-shadow( 0px 4px rgba($color: #000000, $alpha: 0.6));
    transform: translateY(2px);
  }
}

.downloadBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 280px;
  height: 75px;
  clip-path: polygon(0 0, 100% 0, 266px 100%, 16px 100%);
  background: #00ccff;
  cursor: pointer;
  span {
    font-family: 'Russo One', sans-serif;
    color: #ffffff;
    font-size: 30px;
  }
}


@media screen and (max-width: 1500px) {
  .oprorLogo {
    min-width: 400px;
    max-width: 1000px;
  }
  
  .textWrap {
    max-width: 600px;
  }
}

@media screen and (max-width: 1100px) {
  .main {
    height: calc(100vh - 65px);
  }
}

@media screen and (max-width: 768px) {

  .main{
    background-image: url("../../images/Backgrounds/bgMainMobile.png");
    height: fit-content;
  }

  .wrap{
    flex-direction: column;
    height: 100%;
  }

  .oprorLogo {
    width: 100%;
    min-width: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
  }
  
  .column {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .textWrap {
    min-height: unset;
    max-width: unset;
    z-index: 2;
  }
  
  .title {
    font-size: 35px;
  }
  
  .text {
    font-size: 16px;
    margin: 10px 0 40px;
  }
  
  .downloadBtn {
    width: 160px;
    height: 45px;
    clip-path: polygon(0 0, 100% 0, 150px 100%, 10px 100%);
    span {
      font-size: 23px;
    }
  }


}
.main{
  position: relative;
  width: 100%;
  height: 800px;
  background: linear-gradient(to bottom, rgba($color: #000000, $alpha: 1.0) -20%, rgba($color: #2f0000, $alpha: 1.0) 100%);
}

.background {
  position: absolute;
  height: 100%;
  width: 100%;
  background: url("../../images/Backgrounds/frame2_cards.svg") no-repeat center;
}

.wrap {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 40px 0;
}

.titleWrap {
  display: block;
  text-align: center;
  margin-bottom: 40px;
}

.title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #ffffff;
  font-size: 45px;
}

.cardLayer {
  display: flex;
  max-width: 1650px;
  height: 500px;
  margin: auto;
  justify-content: center;
  flex-shrink: 1;
}

.cardCarousel {
  display: none;
}

@media screen and (max-width: 1500px) {
  .main{
    width: 100%;
    height: 650px;
  }
}

@media screen and (max-width: 992px) {

  .main{
    height: calc(80vh);
    min-height: 670px;
  }

  .background {
    background-size: cover;
  }

  .carouselBg {
    position: absolute;
    width: 280px;
    height: 70px;
    bottom: -16px;
    left: 50%;
    transform: translateX(-50%);
    background: url("../../images/Images/carousel_frame_mobile.svg") no-repeat center;
    background-size: contain;
  }
  
  .wrap {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 10px 0;
  }
  
  .titleWrap {
    height: 50px;
    margin-bottom: 10px;
  }
  
  .title {
    margin-bottom: -10px;
    font-size: 35px;
  }

  .cardCarousel {
    display: block;
  }
  
  .carousel {
    width: 100%;
    height: calc(80vh - 70px);
    min-height: 600px;
    user-select: none;
    z-index: 2;
  }

  .carouselDiv {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 250px;
    height: 555px;
  }


  .cardLayer {
    display: none;
  }

  .indicatorStyles {
    width: 22px;
    height: 22px;
    display: inline-block;
    margin: -8px 10px;
    background: #000;
    cursor: pointer;
  }
}

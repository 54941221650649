.main {
  width: 100%;
  min-height: calc(100vh - 325px);
  background: linear-gradient(
    rgba($color: #000000, $alpha: 1.0) 0%, 
    rgba($color: #001e35, $alpha: 1.0) 100%,
    );
  }
  
.wrap{
  position: relative;
  width: 100%;
  min-height: calc(100vh - 325px);
  height: 100%;
  background: url("../../images/Backgrounds/backgroundNews.png") no-repeat;
  background-position: 50% 0%;
  background-size: 100%;
}

.skullWrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.skullBg {
  position: absolute;
  top: 0;
  left: 50%;
  width: 1400px;
  padding-top: 100%;
  transform: translateX(-50%);
  background: url("../../images/News/texture_skull.svg") no-repeat;
  background-position: 50% 0%;
  background-size: 100%;
}

.backgroundStyle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(
    rgba($color: #ffffff, $alpha: 0.05) 0px,
    rgba($color: #ffffff, $alpha: 0.05) 1px,
    rgba($color: #ffffff, $alpha: 0) 1px,
    rgba($color: #ffffff, $alpha: 0) 5px);
}

.headerWrap {
  max-width: 1650px;
  width: calc(100% - 200px);
  height: 500px;
  margin: auto;
  background: rgba($color: #00ccff, $alpha: 1.0);
  box-sizing: border-box;
  padding: 0 0 2px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 80% 100%, calc(80% - 20px) 95%, calc(20% + 20px) 95%, 20% 100%, 0 100%);
  z-index: 2;
}

.headerBackground {
  position: relative;
  width: 100%;
  height: 100%;
  background: url("../../images/Backgrounds/Render_7.png") no-repeat bottom;
  background-size: cover;
  background-position: center;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 80% 100%, calc(80% - 18px) 95%, calc(20% + 18px) 95%, 20% 100%, 0 100%);
}

.headerFilter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background:
    linear-gradient(
    rgba($color: #000000, $alpha: 0.5) 0%,
    rgba($color: #000000, $alpha: 0.0) 100%,
    );
}

.titleContainer {
  margin: 0 auto;
  max-width: 1650px;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 50px;
  z-index: 2;
}

.arrowBack {
  display: flex;
  justify-content: flex-end;
  width: 15%;
}

.arrowNext {
  width: 15%;
}

.titleWrap {
  width: 70%;
  flex-direction: column;
  display: flex;
  margin-top: -20px;
  padding-bottom: 10px;
  justify-content: center;
  border-bottom: 2px solid rgba($color: #00ccff, $alpha: 1.0);
}

.subtext {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  font-weight: 600;
  color: #cccccc;
  font-size: 20px;
}

.title {
  font-family: 'Russo One', sans-serif;
  text-align: center;
  font-weight: 700;
  color: #ffffff;
  font-size: 58px;
}

.infoContainer {
  z-index: 2;
}

.date {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  font-style: italic;
  color: #cccccc;
  font-size: 16px;
}

.infoNotes {
  margin: 0 auto;
  max-width: 1650px;
  display: flex;
  width: 100%;
  padding: 0 200px;
  box-sizing: border-box;
}

.recentNotes {
  margin: 40px auto 0;
  height: 100%;
  width: 100%;
  display: flex;
  max-width: 1650px;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 20px ;
  
}

.recentNews {
  margin: auto;
  width: calc(100% - 360px);
  border-top: 2px solid rgba($color: #00ccff, $alpha: 1.0);
  padding: 25px 0; 
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  font-style: italic;
  font-weight: 700;
  color: #ffffff;
  font-size: 35px;
}

@media screen and (max-width: 992px) {

  .wrap{
    background: url("../../images/Backgrounds/backgroundNews.png") no-repeat;
    background-position: 50% 10%;
    background-size: 100%;
  }
  
  .headerWrap {
    width: 100%;
    height: 300px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 90% 100%, calc(90% - 20px) 95%, calc(10% + 20px) 95%, 10% 100%, 0 100%);
  }
  
  .headerBackground {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 90% 100%, calc(90% - 18px) 95%, calc(10% + 18px) 95%, 10% 100%, 0 100%);
  }

  .skullWrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .skullBg {
    position: absolute;
    top: 0;
    left: 50%;
    width: 700px;
    height: 100%;
    padding-top: 100%;
    transform: translateX(-50%);
  }

  .titleContainer {
    margin-bottom: 20px;
  }

  .infoNotes {
    padding: 0;
  }

  .recentNotes {
   padding: 0 ;
  }

  .recentNews {
    width: 100%;
  }  

  .subtext {
    font-size: 16px;
    margin-top: 8px;
  }

  .title {
    font-size: 38px;
  }

  .date {
    font-size: 14px;
  }

}
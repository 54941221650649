.main {
  display: flex;
  position: relative;
  height: calc(100vh - 325px);
  img {
    margin: auto;
    filter: blur(2px);
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-width: 1650px;
  }
}
.comingSoon {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0,0,0,0.7);
  position: absolute;
  font-family: 'Russo one', sans-serif;
  font-size: 80px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}


@media screen and (max-width: 992px) {
  .comingSoon {
    font-size: 40px;
  }
}

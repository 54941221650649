.main {
  display: block;
  width: 100%;
  height: 100%;
}

.card {
  display: flex;
  width: 100%;
  height: calc(100% - 100px);
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  margin: 20px 0;
  &:hover {
    .cardFrame {
      opacity: 0.7;
      transform:scale(0.87, 0.95);
      -webkit-transition-duration: 0.2s;
      transition-duration: 0.2s;
    }
  }
}

.cardFrame {
  position: absolute;
  display: flex;
  height: 100%;
  width: 310px;
  opacity: 0.39;
}

.imageBackEffect, .imageFrontEffect {
  position: relative;
  width: 280px;
  height: 100%;
}

.imageFrontEffect {
  .cardBackImg {
    transform: rotateY(90deg);
  }
  .cardFrontImg {
    transform: rotateY(0deg);
    transition-delay: .4s;
  }
}

.imageBackEffect {
  .cardBackImg {
    transition-delay: .4s;
  }
}

.cardBackImg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: .4s;
}

.cardFrontImg {
  position: absolute;
  top: 13px;
  left: 13px;
  width: 252px;
  height: 376px;
  transform: rotateY(90deg);
  transition: .4s;
}

.cardTitleText {
  font-family: 'Russo One', sans-serif;
  text-align: center;
  color: #ffffff;
  font-size: 28px;
}

.cardSubText {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  font-weight: 500;
  color: #ffffff;
  font-size: 19px;
  padding: 6px 20px;
}

@media screen and (max-width: 1500px) {
  
.card {
  display: flex;
  width: 100%;
  height: calc(100% - 250px);
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  margin: 20px 0;
  &:hover {
    .cardFrame {
      opacity: 0.7;
      transform:scale(0.87, 0.95);
      transition-duration: 0.2s;
    }
  }
}

.cardFrame {
  width: 310px;
}

.cardBackImg {
  width: 175px;
}
.cardFrontImg {
  position: absolute;
  top: 8px;
  left: 10px;
  width: 156px !important;
  height: 234px !important;
  transform: rotateY(90deg);
  transition: .4s;
}

.imageBackEffect, .imageFrontEffect {
  position: relative;
  width: 176px;
  height: 100%;
}

}

@media screen and (max-width: 992px) {

  .card {
    display: flex;
    width: 100%;
    height: calc(100% - 200px);
    position: relative;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    margin: 20px 0;
    &:hover {
      .cardFrame {
        opacity: 0.7;
        transform:scale(0.87, 0.95);
        transition-duration: 0.2s;
      }
    }
  }

  .cardFrame {
    width: 310px;
  }
  .cardFrontImg {
    position: absolute;
    top: 13px;
    left: 13px;
    width: 221px !important;
    height: 330px !important;
    transform: rotateY(90deg);
    transition: .4s;
  }

  .imageBackEffect, .imageFrontEffect {
    position: relative;
    width: 280px;
    height: 100%;
  }
}
.main {
  height: 240px;
}

.wrap{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  background: #000000;
  align-items: center;
}

.backgorundImg {
  position: absolute;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  background: url("../../images/Logos/logo_footer.png") no-repeat;
  background-position: calc(50% - 500px);
  z-index: 1;
}

.navWrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 2;
}

.column {
  margin-right: 60px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.squarelessBtn {
  height: fit-content;
  padding: 0 20px;
  margin: 20px 10px;
  cursor: pointer;
  text-decoration: none;

  span {
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    font-weight: 500;
    color: #ffffff;
    font-size: 20px;
    padding: 4px 8px;
  }

  &:hover span{
    text-shadow: 0px 0px 15px #00ccff;
    color: #00ccff;
  }
}

.dropbox, .dropboxActive {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  cursor: pointer;
  transform: translateY(10px);

  div {
    margin: 0 10px;
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    font-weight: 500;
    color: #ffffff;
    font-size: 20px;
  }

  &:hover span{
    text-shadow: 0px 0px 20px #00ccff;
    color: #00ccff;
  }
}


.dropboxActive {
  .iconArrow {
    transform: rotateX(180deg); 
  }
}

.iconLanguage {
  width: 36px;
  height: 36px;
}

.languageWrap {
  position: absolute;
  bottom: 50px;
  left: 10px;
  background: #222222;
  padding: 16px 20px;
  border-radius: 6px;
  text-align: center;
  box-shadow: 0px 1px 9px -1px rgba(0, 204, 254, 0.5);
  box-sizing: border-box;
  .languageSelector {
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: #ffffff;
    padding: 10px;
    cursor: pointer;
  }
}

.iconArrow {
  margin-left: 10px;
  width: 17px;
  height: 17px;
  transition-duration: .3s;
}

@media screen and (max-width: 992px) {

  .wrap{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .backgorundImg {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("../../images/Logos/logo_footer.png") no-repeat;
    background-position: calc(50% - 200px);
    z-index: 1;
  }
  
  .navWrap {
    width: auto;
    margin-left: 90px;
    display: flex;
    flex-direction: column;
  }
  
  .column {
    margin-right: 0px;
  }
  
  .squarelessBtn {
    padding: 0px;
    margin: 6px 0px;
  
    span {
      font-size: 15px;
    }
    }
  
  .dropbox, .dropboxActive {
  
    div {
      font-size: 15px;
    }
  }

  .iconLanguage {
    width: 30px;
    height: 30px;
  }
  
  .languageWrap {
    .languageSelector {
      font-size: 15px;
    }
  }
  
}
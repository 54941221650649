.boxRow {
  width: 100%;
  display: flex;
}

.boxReverse {
  width: 100%;
  display: flex;

  .imageBox {
    padding: 0 15% 0 0;
    flex-flow: row-reverse;
  }
  .mainImage {
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 33px), calc(100% - 37px) 100%, 0 100%);
  }
  .shadowImage {
    margin: 28px 127px 0 0;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 33px), calc(100% - 37px) 100%, 0 100%);
  }
}

.imageBox {
  width: 100%;
  height: 346px;
  position: relative;
  display: flex;
  box-sizing: border-box;
  padding: 0 0 0 15%;
}

.mainImage {
  position: absolute;
  top: 0;
  width: 717px;
  height: 318px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 37px 100%, 0 calc(100% - 33px));
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  transition-duration: 0.5s;
  cursor: pointer;
  &:hover {
    background-size: 130%;
  }
  
}

.shadowImage {
  width: 100%;
  height: 318px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 37px 100%, 0 calc(100% - 33px));
  margin: 28px 0 0 127px;
  background-repeat: no-repeat;
  background-size: 100% 300%;
  background-position: 0 50%;
  opacity: 0.3;
}

.imageBoxMobile {
  display: none;
}

@media screen and (max-width: 992px){

  .imageBox {
    display: none;
  }
    
  .boxReverse {
    .imageBox {
      padding: 0 15% 0 0;
      flex-flow: row-reverse;
    }
    .mainImageMobile {
      clip-path: polygon(0 0, 100% 0, 100% 87%, 92% 100%, 0 100%);
      margin: 0 5% 0 0;
    }
    .shadowImageMobile {
      clip-path: polygon(0 0, 100% 0, 100% 88%, 93% 100%, 0 100%);

    }
  }

  .imageBoxMobile {
    display: flex;
    width: 100%;
    height: calc(100vw * 0.60);
    padding: 0 0 20px;
  }

  .mainImageMobile {
    position: relative;
    width: 100%;
    margin: 0 0 0 5%;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 8% 100%, 0 87%);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    cursor: pointer;
  }

  .shadowImageMobile {
    position: absolute;
    width: 100%;
    height: calc(100vw * 0.60);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 7% 100%, 0 88%);
    background-repeat: no-repeat;
    background-size: 100% 300%;
    background-position: 0 50%;
    opacity: 0.3;
  }

}

.main {
  width: 100%;
  background: rgba($color: #000000, $alpha: 0.8);
  padding: 0 50px 30px;
  box-sizing: border-box;
  >p {
    display: flex;
    flex-direction: column;
  }
  img {
    align-self: center;
  }
}

h1 {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  font-weight: 700;
  color: #ffffff;
  font-size: 35px;
}

h2 {
  font-family: 'Montserrat', sans-serif;
  text-align: left;
  font-weight: 700;
  color: #ffffff;
  font-size: 25px;
}

b {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: italic;
  color: #00ccff;
  font-size: 22px;
}

p {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  color: #ffffff;
  font-size: 22px;
}

.imgContainer {
  margin: auto;
  margin-top: 40px;
  position: relative;
  width: 90%;
  padding-top: 50%;
  height: auto;
}

.frameImage {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  clip-path: polygon(3% 0, 100% 0, 100% 94%, 97% 100%, 0 100%, 0 6%);
  background: rgba($color: #00ccff, $alpha: 1.0);
  box-sizing: border-box;
  padding: 2px;
}

.imageBox {
  width: 100%;
  height: 100%;
  clip-path: polygon(3% 0, 100% 0, 100% calc(94% + 2px), calc(97% + 2px) 100%, 0 100%, 0 6%);
  background: rgba($color: #000000, $alpha: 1.0);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.screenshotWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}


.frameScreenshot {
  width: 30%;
  clip-path: polygon(31px 0, 100% 0, 100% calc(100% - 31px), calc(100% - 31px) 100%, 0 100%, 0 31px);
  background: rgba($color: #00ccff, $alpha: 1.0);
  box-sizing: border-box;
  padding: 1px;
  margin-bottom: 20px;
}

.bgScreenShot {
  width: 100%;
  height: 100%;
  clip-path: polygon(30px 0, 100% 0, 100% calc(100% - 30px), calc(100% - 30px) 100%, 0 100%, 0 30px);
  background: linear-gradient(
    rgba($color: #000000, $alpha: 1.0) 0,
    rgba($color: #001e35, $alpha: 1.0) 100%,
  );
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
  box-sizing: border-box;
}

.imgScreenShot {
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 992px) {

  .main {
    width: 100%;
    background: rgba($color: #000000, $alpha: 0.6);
    padding: 0 20px 30px;
    box-sizing: border-box;
    img {
      width: 100%;
    }
  }

  .imgContainer {
    width: 100%;
  }

  .screenshotWrap {
    justify-content: space-between;
  }


  .frameScreenshot {
    width: 48%;
  }
  
  b {
    font-size: 16px;
  }
  
  p {
    font-size: 16px;
  }
  
}
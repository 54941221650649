.main {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.wrap{
  position: relative;
  width: 100%;
  height: auto;
  background: linear-gradient(to bottom, rgba($color: #000000, $alpha: 1.0) -20%, rgba($color: #2f0000, $alpha: 1.0) 100%);
}

.background {
  position: absolute;
  width: 1650px;
  height: 1000px;
  left: 50%;
  background: url("../../images/Backgrounds/BG_heroes.svg") no-repeat center;
  background-size: 100%;
  transform: translateX(-50%);

}

.carouselFrame {
  width: 1100px;
  height: 70px;
  position: absolute;
  bottom: -3px;
  left: 50%;
  transform: translateX(-50%);
  background: url("../../images/Images/carousel_frame.svg") no-repeat center;
  background-size: 100%;
  z-index: 1;
}

.titleWrap {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  text-align: center;
}

.title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #ffffff;
  font-size: 45px;
}

.borderImg {
  width: 280px;
}

.indicatorStyles {
  width: 24px;
  height: 24px;
  display: inline-block;
  margin: 8px 26px;
  background: #000;
  cursor: pointer;
}

.carousel {
  overflow: hidden;
  width: 100%;
  height: 100%;
  user-select: none;
  z-index: 2;
}

@media screen and (max-width: 992px) {
  
  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    background-size: 200%;
    transform: translateX(-50%);
  
  }

  .titleWrap {
    position: relative;
    text-align: center;
  }

  .title {
    font-size: 36px;
    margin-bottom: -10px;
  }

  .carouselFrame {
    width: auto;
    height: 70px;
    position: absolute;
    bottom: -1px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-user-drag: none;
    z-index: 1;
  }

  .indicatorStyles {
    width: 22px;
    height: 22px;
    display: inline-block;
    margin: -8px 10px;
    background: #000;
    cursor: pointer;
  }  

  .carouselFrame {
    width: 280px;
    height: 70px;
    position: absolute;
    bottom: -18px;
    left: 50%;
    transform: translateX(-50%);
    background: url("../../images/Images/carousel_frame_mobile.svg") no-repeat center;
    background-size: 100%;
    z-index: 1;
  }
}
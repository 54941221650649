.main {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  height: 830px;
}

.heroImg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
  left: -400px;
  top: 0;
}

.carouselText {
  position: relative;
  width: 100%;
  height: 100%;
}

.textWrap {
  display: flex;
  position: absolute;
  justify-content: flex-end;
  left: calc(50% - 220px);
  top: 50%;
  width: 785px;
  height: 280px;
  transform: translateY(-50%);
  z-index: 1;
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 130px;
  left: 50%;
  transform: translateX(-50%);
  background: url("../../images/Images/element_infoHeroes.svg") no-repeat;
}

.textBox {
  width: 530px;
  margin-right: 20px;
  height: auto ;

}

.heroName {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #ffffff;
  font-size: 35px;
}

.heroSubtitle {
  margin: 20px 0;;
  font-family: 'Montserrat', sans-serif;
  font-style: italic;
  font-weight: 500;
  color: #00ccff;
  font-size: 23px;
}

.heroText {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  color: #ffffff;
  font-size: 20px;
}

@media screen and (max-width: 1100px) {
  .textWrap {
    display: flex;
    position: absolute;
    justify-content: flex-end;
    left: calc(50% - 300px);
    top: 50%;
    width: 785px;
    height: 280px;
    transform: translateY(-50%);
    z-index: 1;
  }

  .textBox {
    width: 500px;
    margin-right: 20px;
    height: auto ;
  
  }
}

@media screen and (max-width: 992px) {
  .main {
    height: calc(100vh - 150px);
  }
  .background {
    display: none;
  }
  
  .heroImg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
    left: 0;
    bottom: 0;
  }

  .textWrap {
    display: flex;
    position: absolute;
    justify-content: flex-end;
    left: 50%;
    top: 60%;
    width: 100%;
    transform: translateX(-50%);
    z-index: 1;
    box-sizing: border-box;
    padding: 20px;
    background: rgba($color: #000000, $alpha: 0.6);
    
  }
  
  .textBox {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
  }

  .heroName {
    font-size: 25px;
  }

  .heroSubtitle {
    margin: 0 0 5px;
    font-style: italic;
    font-size: 16px;
  }

  .heroText {
    font-size: 16px;
  }

}
@media screen and (max-width: 768px) {
  .heroImg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    left: 0;
    bottom: 0;
  }

  .textWrap {
    display: flex;
    position: absolute;
    justify-content: flex-end;
    left: 50%;
    top: unset;
    bottom: 10%;
    width: 100%;
    transform: translateX(-50%);
    z-index: 1;
    box-sizing: border-box;
    padding: 20px;
    background: rgba($color: #000000, $alpha: 0.6);
  
  }
  .heroText {
    font-size: 12px;
  }
}


@media screen and (max-width: 812px) and (orientation: landscape) { 

  .textWrap {
    display: flex;
    position: absolute;
    justify-content: flex-end;
    left: 50%;
    top: unset;
    bottom: 0;
    width: 100%;
    transform: translateX(-50%);
    z-index: 1;
    box-sizing: border-box;
    padding: 20px;
    background: rgba($color: #000000, $alpha: 0.6);
  }

}
.boxRow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.boxReverse {
  display: flex;
  flex-flow: row-reverse;
  justify-content: center;
  align-items: center;
}

.textBox {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 580px;
}

.backgroundSvg {
  position: absolute;
}

.title {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  font-weight: 700;
  color: #373737;
  font-size: 45px;
}

.text {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  font-weight: 500;
  color: #707070;
  font-size: 20px;
  margin-top: 25px;
}

.imageBox {
  max-width: 580px;
  display: flex;
  position: relative;
}

.blueContainerSvg {
  width: 500px;
  height: 282px;
  bottom: 25px;
  left: calc(50% + 4px);
  transform: translateX(-50%);
  position: absolute;
  z-index: 1;
}

.contentImg {
  width: 562px;
  z-index: 2;
}


@media screen and (max-width: 992px){

  .boxRow, .boxReverse {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    position: relative;
  }

  .backgroundSvg {
    top: 0px
  }

  .title {
    font-size: 33px;
  }
  
  .text {
    font-size: 16px;
    margin-top: 15px;
  }

  
  .imageBox {
    width: 100%;
    padding: 0 20px;
    display: flex;
    position: relative;
    box-sizing: border-box;
  }

  .blueContainerSvg {
    width: 82%;
    height: 90%;
    bottom: 10px;
    left: calc(50% + 3px);
    transform: translateX(-50%);
    position: absolute;
    z-index: 1;
  }
  
  .contentImg {
    width: 100%;
    z-index: 2;
  }

}
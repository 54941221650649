.main {
  width: 100%;
  height: calc(100vh - 325px);
  min-height: 600px;
}

.wrap{
  position: relative;
  width: 100%;
  height: 100%;
  background:
  repeating-linear-gradient(
    rgba($color: #ffffff, $alpha: 0.05) 0px,
    rgba($color: #ffffff, $alpha: 0.05) 1px,
    rgba($color: #ffffff, $alpha: 0) 1px,
    rgba($color: #ffffff, $alpha: 0) 5px),
    linear-gradient(to bottom, rgba($color: #000000, $alpha: 1) 0%, rgba($color: #373737, $alpha: 1) 100%);
}

.logoBg {
  position: absolute;
  width: 100%;
  height: 800px;
  background: url("../../images/Logos/bg_logoGrayscale.png") no-repeat center;
  background-size: contain;
}

.container {
  margin: auto;
  height: 100%;
  display: flex;
  max-width: 1650px;
  justify-content: center;
  align-items: center;
}

.textWrap {
  max-width: 800px;
  display: block;
  width: 100%;
  text-align: left;
}

.title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #ffffff;
  font-size: 55px;
}

.text {
  position: relative;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  color: #ffffff;
  font-size: 22px;
  user-select: none;
  a {
    text-decoration: none;
    color: #ffffff;
  }
}

@media screen and (max-width: 1100px)  {

  .main {
    height: calc(100vh - 305px);
  }
}

  @media screen and (max-width: 992px) {
  .main {
    height: auto;
    min-height: calc(100vh - 305px);
  }

  .wrap{
    min-height: calc(100vh - 305px);
  }

  .logoBg {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("../../images/Logos/bg_logoGrayscale.png") no-repeat;
    background-position: 50% 50px;
    background-size: 500px;
  }

  .container {
    flex-direction: column;
    max-width: auto;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .textWrap {
    max-width: auto;
    text-align: center;
  }

  .title {
    font-size: 33px;
  }

  .text {
    font-size: 16px;
  }
}

.main {
  height: 85px;
  user-select: none;
}

.fixedMain{
  position: fixed;
  height: 85px;
  width: 100%;
  background: #000000;
  z-index: 5;
}

.wrap{
  position: relative;
  width: 100%;
  max-width: 1650px;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.logoWrap {
  position: absolute;
  top: 50%;
  left: 100px;
  display: flex;
  align-items: center;
  transform: translateY(-50%);
}

.logo {
  width: 253px;
  height: 64px;
  background: url("../../images/Logos/logo_header.png") no-repeat center;
  background-size: cover;
  cursor: pointer;
}

.navWrap {
  display: flex;
  align-items: center;
  padding-right: 100px;
}

.squarelessBtn {
  height: fit-content;
  padding: 0 12px;
  margin: 0 10px;
  cursor: pointer;
  text-decoration: none;

  span {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: #ffffff;
    font-size: 20px;
    padding: 4px 8px;
  }

  &:hover span{
    text-shadow: 0px 0px 15px #00ccff;
    border-bottom: 1px solid #00ccff;
  }
}
.squareBtn {
  height: fit-content;
  margin-left: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  background: #00ccff;
  cursor: pointer;
  text-decoration: none;
  span {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: #ffffff;
    font-size: 20px;
  }
}

.wrapMobile {
  display: none;
}

.wallet {
  button {
    background: transparent !important;
  }
}


@media screen and (max-width: 992px), screen and (max-width: 1350px) {

  .main {
    height: 65px;
  }

  .fixedMain{
    height: 65px;
  }

  .wrap{
    display: none;
  }

  .wrapMobile {
    position: relative;
    display: block;
    width: 100%;
    height: 65px;
  }

  .containerMobile {
    position: fixed;
    top: 0;
    display: flex;
    width: 100%;
    height: 65px;
    background: rgba($color: #000000, $alpha: 1);
    justify-content: center;
    align-items: center;

  }

  .logoWrap {
    top: 50%;
    left: 20px;
  }

  .logo {
    width: 180px;
    height: 50px;
    background: url("../../images/Logos/logo_header.png") no-repeat center;
    background-size: cover;
    cursor: pointer;
  }

  .menuMobile {
    position: absolute;
    display: flex;
    top: 50%;
    right: 22px;
    transform: translateY(-50%);
    width: 25px;
    height: 17px;
    background: url("../../images/Images/icon_menuHeader_mobile.svg") no-repeat center;
    background-size: cover;
    text-decoration: none;
  }

  .menuBox {
    position: fixed;
    top: 65px;
    right: 0;
    width: 240px;
    height: 145px;
    box-shadow: -2px 2px 10px rgba($color: #000000, $alpha: 1);
    z-index: 3;
    display: flex;
    flex-direction: column;
  }

  .mobileBtn, .mobileBtnBlue {
    width: 100%;
    height: 48px;
    background: rgba($color: #00485A, $alpha: 0.92);
    border-bottom: 1px solid rgba($color: #00ccff, $alpha: 0.65);
    border-left: 1px solid rgba($color: #00ccff, $alpha: 0.65);
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    text-decoration: none;
    span {
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      color: #ffffff;
      font-size: 19px;
    }
  }

  .mobileBtnBlue {
    background: rgba($color: #00ccff, $alpha: 0.92);
  }

  .walletMobile {
    button {
      width: 100%;
      height: 40px;
      background: rgba(0, 72, 90, 0.92) !important;
      border-bottom: 1px solid rgba(0, 204, 255, 0.65);
      border-left: 1px solid rgba(0, 204, 255, 0.65);
      display: flex;
      color: #ffffff;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      text-decoration: none;
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      font-size: 19px;
      border-radius: 0
    }
    >div {
      width: 100%;
    }
  }
}

.main {
  position: relative;
  height: auto;
  width: 100%;
  background: #ffffff;

}
  
.wrap{
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  max-width: 1650px;
  height: 100%;
  background: #ffffff;
  padding: 40px 0 ;
}

.drones {
  position: absolute;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.drone1 {
  position: absolute;
  left: calc(50% - 1000px);
  width: 400px;
  height: auto;
  z-index: 3;

  animation-name: droneMove;
  animation-duration: 20s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  
  @keyframes droneMove {
    0%   {transform: translate(0px, 0px);}
    20%  {transform: translate(20px, 20px);}
    40%  {transform: translate(-30px, 0);}
    50%  {transform: translate(0, -20px);}
    75%  {transform: translate(20px, -10px);}
    0% {transform: translate(0, 0);}
  }
}

.drone2 {
  position: absolute;
  right: calc(50% - 1000px);
  width: 400px;
  height: auto;
  z-index: 3;
  animation-name: droneMove2;
  animation-delay: 4s;
  animation-duration: 20s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  
  @keyframes droneMove2 {
    0%   {transform: translate(0%, 0%);}
    25%  {transform: translate(-5%, -5%);}
    50%  {transform: translate(5%, 0%);}
    75%  {transform: translate(-5%, -5%);}
    0% {transform: translate(0%, 0%);}
  }
}

@media screen and (max-width: 1100px) {

.drone1, .drone2 {
  display: none;
}

}
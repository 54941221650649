.main {
  width: 100%;
  height: calc(100vh - 325px);
  min-height: 600px;
  color: white;
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  background: linear-gradient(
    rgba($color: #000000, $alpha: 1.0) 0%,
    rgba($color: #001e35, $alpha: 1.0) 100%,
    );}

.wrap {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  h3 {
    font-family: 'Montserrat', sans-serif;
    font-size: 45px;
    text-align: center;
    margin: 18px 0 0 0;
    font-weight: 600
  }
  h4 {
    font-family: 'Russo One', sans-serif;
    font-size: 25px;
    text-align: center;
    margin: 0 0;
    font-weight: 500
  }
  h5 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    // max-width: 800px;
    margin: 24px auto;
  }
  span {
    color: #00ccff;
  }
}

.card1 {
  position: absolute;
  top: 10%;
  right: -12%;
  width: 40%
}
.card2 {
  position: absolute;
  top: -15%;
  left: -30%;
}
.card3 {
  position: absolute;
  top: -12%;
  right: 32%;
}
.card4 {
  position: absolute;
  bottom: 15%;
  left: -13%;
}
.card5 {
  position: absolute;
  right: -20%;
  bottom: 15%;
}

.content {
  z-index: 1;
  position: relative;
}

.underline {
  margin: auto;
  width: 280px;
  height: 10px;
  margin: 8px auto;
  background-image: url( "../../images/Images/line_titles.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
.optionsWrap {
  margin: 60px 0
}
.option {
  width: 100%;
  max-width: 905px;
  margin: 50px auto;
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 16px 40px 16px 16px;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
  border: none;
  &:hover {
    filter:drop-shadow(0px 0px 0px rgba(0, 53, 67, 1))
  }
  .bgBorder {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    margin: -20px;
    display: block;
  }
  .icon {
    height: 170px;
    width: 170px;
  }
}

.optionHeader {
  font-family: 'Russo One', sans-serif;
  font-size: 23px;
}
.optionListItem {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  display: flex;
  margin: 6px 0;
  strong {
    font-family: monospace;
    background: #2d2d2d;
    padding: 0 2px
  }
}
.listBullet {
  background-image: url( "../../images/icons/icon_point_texts.svg");
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  min-width: 16px;
}
.disclaimer{
  margin: 50px 0;
  .optionHeader {
    font-size: 16px;
    display: flex;
  }
  .optionListItem {
    font-style: italic;
  }
}
.optionImgWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.optionHeaderMobile {
  display: none;
}

.enterWalletWrap {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 500px;
  margin-top: 40px;
  div {
    font-size: 24px;
    margin-bottom: 20px
  }
}
.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 12px;
  box-sizing: border-box;
  width: 100%;
  height: 51px;
  background: rgba($color: #003e4d, $alpha: 0.6);
  clip-path: polygon(0 7px, 7px 0, 100% 0, 100% calc(100% - 7px), calc(100% - 7px) 100%, 0% 100%);
  input {
    width:100%;
    border: none;
    background: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: #ffffff;
    font-size: 25px;
    outline: none;
    cursor: text;
  }
}
.mintButton {
  width: 170px;
  height: 46px;
  border-radius: 4px;
  color: #ffffff;
  font-size: 20px;
  background: #00ccff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px auto 0;
  cursor: pointer;
}
.mintButtonDisabled {
  width: 170px;
  height: 46px;
  border-radius: 4px;
  color: #ffffff;
  font-size: 20px;
  background: #dedede;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px auto 0;
}
.wallet {
  margin-top: 8px
}

@media screen and (max-width: 1100px)  {
  .main {
    // height: calc(100vh - 305px);
  }
}

@media screen and (max-width: 992px) {
  .main {
    height: auto;
    // min-height: calc(100vh - 305px);
  }

  .wrap{
    // min-height: calc(100vh - 305px);
  }
  .content {
    padding: 16px;
  }
  .option {
    border: 1px solid #007d9e;
    background: rgba(0, 0, 0, 0.6);
    flex-direction: column;
    .bgBorder {
      display: none;
    }
    .icon {
      height: 100px;
      width: 100px;
    }
  }
  .optionHeader {
    display: none
  }
  .optionHeaderMobile {
    display: flex;
    font-family: 'Russo One';
    font-weight: 500;
    font-size: 23px
  }
  .enterWalletWrap {
    border: 1px solid #007d9e;
    background: rgba(0, 0, 0, 0.6);
    padding: 8px
  }
}

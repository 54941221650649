
.container {
  display: flex;
  position: relative;
  width: 480px;
  height: 450px;
  background: #0cf;
  clip-path: polygon(31px 0, 100% 0, 100% calc(100% - 31px), calc(100% - 31px) 100%, 0 100%, 0 31px);
  box-sizing: border-box;
  padding: 2px;
  cursor: pointer;
  text-decoration: none;
}

.container:hover {
  .imageBox{
    background-size: 120%;
  }
  .textBox {
    background: linear-gradient( 
      rgba($color: #000000, $alpha: 0) 0%,
      rgba($color: #004f63, $alpha: 1.0) 100% );
  }
}
  
.boxWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 1.0);
  clip-path: polygon(31px 0, 100% 0, 100% calc(100% - 31px), calc(100% - 31px) 100%, 0 100%, 0 31px);
  box-sizing: border-box;
}

.imageBox {
  width: 100%;
  height: 100%;
  background-image: url("../../images/placeholder/placeholder.png");
  background-position: center;
  background-size: 100%;
  transition-duration: 0.5s;
  box-shadow: 0 -15px 20px -10px rgba($color: #000000, $alpha: 1.0) inset;
}

.textBox {
  position: relative;
  width: 100%;
  height: 90%;
  padding: 5px 15px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: linear-gradient( 
    rgba($color: #000000, $alpha: 0) 0%,
    rgba($color: #002a34, $alpha: 1.0) 100% );
  z-index: 1;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 29px), calc(100% - 29px) 100%, 0 100%, 0 0);
  box-sizing: border-box;
}

.subtext {
  font-family: 'Montserrat', sans-serif;
  text-align: left;
  font-weight: 600;
  color: #cccccc;
  font-size: 20px;
  z-index: 1;
}

.title {
  font-family: 'Russo One', sans-serif;
  text-align: left;
  color: #ffffff;
  font-size: 43px;
  margin-bottom: 5px;
  z-index: 1;
}

.text {
  font-family: 'Montserrat', sans-serif;
  text-align: left;
  font-weight: 400;
  color: #ffffff;
  font-size: 20px;
  z-index: 1;
}

.date {
  position: absolute;
  bottom: 10px;
  left: 15px;
  font-family: 'Montserrat', sans-serif;
  text-align: left;
  font-style: italic;
  color: #cccccc;
  font-size: 18px;
  z-index: 1;
}

.wideContainer {
  display: flex;
  position: relative;
  width: 100%;
  height: 195px;
  margin-bottom: 40px;
  background: rgba($color: #000000, $alpha: 0.6);
  clip-path: polygon(31px 0, 100% 0, 100% calc(100% - 31px), calc(100% - 31px) 100%, 0 100%, 0 31px);
  cursor: pointer;
  text-decoration: none;
  
  &:hover {
    background: rgba($color: #003543, $alpha: 0.6);

    .wideImageBox{
      background-size: 120%;
    }
  }
}

.wideImageBoxWrap {
  width: 522px;
  height: 195px;
  clip-path: polygon(31px 0, 100% 0, 100% 100%, 0 100%, 0 31px);
}

.wideImageBox {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  transition-duration: 0.5s;
}

.wideTextBox {
  width: 100%;
  height: 100%;
  padding: 15px 40px;
  box-sizing: border-box;
}

.wideTitle {
  font-family: 'Russo One', sans-serif;
  text-align: left;
  color: #ffffff;
  font-size: 43px;
  margin: -4px 0 5px;
  z-index: 1;
}

.wideText {
  font-family: 'Montserrat', sans-serif;
  text-align: left;
  font-weight: 400;
  color: #ffffff;
  font-size: 18px;
  z-index: 1;
  margin-bottom: 5px;
}

.wideDate {
  font-family: 'Montserrat', sans-serif;
  text-align: left;
  font-style: italic;
  color: #cccccc;
  font-size: 16px;
  z-index: 1;
}

@media screen and (max-width: 1500px)  {

  
.container {
  width: 300px;
  height: 340px;
}

.subtext {
  font-size: 14px;
  margin-top: 4px;
}

.title {
  font-size: 30px;
  margin-bottom: 3px;
}

.text {
  font-size: 16px;
  margin-bottom: 20px;
}

.date {
  bottom: 3px;
  font-size: 12px;
}

}

@media screen and (max-width: 992px) {

  .container {
    width: 280px;
    height: 300px;
    overflow: hidden;
    margin: 0 20px;
    clip-path: polygon(20px 0, 100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%, 0 20px);

  }
  
  .boxWrap {
  clip-path: polygon(20px 0, 100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%, 0 20px);

  }

  .subtext {
    font-size: 12px;
    margin-top: 0px;
  }
  
  .title {
    font-size: 20px;
    margin-bottom: 3px;
  }
  
  .textBox {
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 18px), calc(100% - 18px) 100%, 0 100%, 0 0);

  }

  .text {
    font-size: 15px;
    margin-bottom: 20px;
  }
  
  .date {
    bottom: 3px;
    font-size: 12px;
  }


  .wideContainer {
    display: flex;
    position: relative;
    width: 100%;
    height: 100px;
    margin-bottom: 40px;
    background: rgba($color: #000000, $alpha: 0.6);
    clip-path: none;
    cursor: pointer;
    
    &:hover {
      background: rgba($color: #003543, $alpha: 0.6);
  
      .wideImageBox{
        transform: scale(1.5);
        background-size: cover;
      }
    }
  }

  .wideImageBoxWrap {
    width: 40%;
    height: 100%;
    clip-path: none;
    overflow: hidden;
  }
  
  .wideImageBox {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition-duration: 0.5s;
  }
  
  
  .wideTextBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }
  
  .wideTitle {
    font-size: 20px;
    margin: -4px 0 5px;
  }
  
  .wideText {
    display: none;
  }
  
  .wideDate {
    font-size: 12px;
  }
  

}
.main {
  width: 100%;
  min-height: calc(100vh - 325px);
  height: auto;
  background: linear-gradient(
    rgba($color: #000000, $alpha: 1.0) 0%, 
    rgba($color: #001e35, $alpha: 1.0) 100%,
    );
}

.wrap{
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  background: url("../../images/Backgrounds/backgroundNews.png") no-repeat;
  background-position: top;
  background-size: contain;
}

.titleBg {
  position: absolute;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #ffffff;
  font-size: 161px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.16;
}

.skullBg {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 1300px;
  height: 900px;
  transform: translateX(-50%);
  background: url("../../images/News/texture_skull.svg") no-repeat;
  background-size: contain;
}

.backgroundStyle {
  position: absolute;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(
    rgba($color: #ffffff, $alpha: 0.05) 0px,
    rgba($color: #ffffff, $alpha: 0.05) 1px,
    rgba($color: #ffffff, $alpha: 0) 1px,
    rgba($color: #ffffff, $alpha: 0) 5px);
}

.container {
  margin-top: 130px;
  height: 100%;
  width: 100%;
  display: flex;
  max-width: 1650px;
  flex-direction: column;
}

.mainNews {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
}

.title {
  margin: 50px auto 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #ffffff;
  font-size: 55px;
}

.underline {
  margin: 0 auto 50px;
  width: 280px;
}

.wideNews {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-bottom: 100px;
  padding: 0 20px;
  box-sizing: border-box;
}

.cardCarousel {
  display: none;
}
@media screen and (max-width: 1500px) {

  .mainNews {
    justify-content: space-evenly;
  }

  .wideNews {
    margin-bottom: 50px;
  }

  
  .skullBg {
    width: 100%;
    height: 600px;
    bottom: 50px;
    background: url("../../images/News/texture_skull.svg") no-repeat center;
    background-size: contain;
  }

}

@media screen and (max-width: 992px) {

  .wrap{
    background-size: 200%;
  }

  .titleBg {
    font-size: 80px;
  }
  
  .skullBg {
    width: 100%;
    height: 600px;
    bottom: 50px;
    background: url("../../images/News/texture_skull.svg") no-repeat bottom;
    background-size: 150%;
  }

  .container {
    margin-top: 100px;
    
  }
  .title {
    font-size: 33px;
  }
  
  .cardCarousel {
    display: block;
  }
  
  .carousel {
    width: 100%;
    user-select: none;
    z-index: 2;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
  }
  
  .underline {
    margin: 0 auto 50px;
    width: 280px;
  }
  
  .indicatorStyles {
    width: 22px;
    height: 22px;
    display: inline-block;
    margin: -8px 10px;
    background: #000;
    cursor: pointer;
  }
  
  .wideNews {
    padding: 0;
  }
  
  .mainNews {
    display: none;
  }
  
}
.main {
  width: 100%;
  height: 100%;
  background-color: black;
}

.router{
  width: 100%;
  height: 100%;
}

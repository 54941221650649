.main {
  margin-top: 100px;
  position: absolute;
  width: 100%;
  height: 100%;
}
.wrap {
  position: relative;
}

.indicatorStyles {
  position: relative;
  width: 24px;
  height: 24px;
  margin: 8px 26px;
  background: #000;
  display: inline-block;
  cursor: pointer;
}

.carousel {
  user-select: none;
  width: 100%;
  user-select: none;
  box-sizing: border-box;
  padding: 0 100px;
}

.carouselImg {
  max-height: calc(100vh - 300px);
  max-width: 1500px;
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

.carouselFrame {
  position: absolute;
  width: 100%;
  max-width: 1100px;
  height: 68px;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  background: url("../../images/Images/carousel_frame.svg") no-repeat center;
  background-size: cover;
}

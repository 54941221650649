.main {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  z-index: 6;
  a {
    text-decoration: none;
    padding: 0 3px;
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    font-weight: 400;
    color: #00ccff;
    font-size: 18px;
  }
}

.mainBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../images/Backgrounds/Background_WN.png");
  background-size: cover;
  background-position: center;
}

.wrap {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(
    to bottom,
    rgba($color: #000000, $alpha: 0.9) 0%,
    rgba($color: #000000, $alpha: 0.9) 20%,
    rgba($color: #00ccff, $alpha: 0.7) 200%);
    z-index: 1;
    padding: 10px;
    box-sizing: border-box;
}

.header {
  width: 270px;
  height: 75px;
  flex-shrink: 0;
  background-image: url("../../images/Logos/logo_header.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  cursor: pointer;
}

.container {
  display: flex;
  width: 715px;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  user-select: none;
  
}

.contactText {
  text-align: center;
  p {
  font-family: 'Montserrat', sans-serif;
  color: #ffffff;
  font-size: 35px;
  font-weight: 400;
  }
 b {
  font-family: 'Montserrat', sans-serif;
  color: #ffffff;
  font-size: 35px;
  font-weight: 700;
 }
}

.inputWrap {
  margin-top: 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  .inputContainer {
    width: 100%;
    height: 97px;
    display: flex;
    flex-direction: column;
    padding: 0 100px;
    box-sizing: border-box;
    margin-top: 20px;
    span {
      font-family: 'Montserrat', sans-serif;
      font-style: italic;
      font-weight: 500;
      color: #00ccff;
      font-size: 20px;
      margin: 0 0 10px 15px;
    }
  }
  input {
    border-radius: 8px;
    outline: none;
    border: 1px solid rgba($color: #00ccff, $alpha: 0.5);
    background-color: rgba($color: #000000, $alpha: 0.8);
    color: rgba($color: #ffffff, $alpha: 1.0);
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    width: 100%;
    height: 45px;
    padding: 0 15px;
    box-sizing: border-box;
    &::placeholder {
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;
      font-style: italic;
      font-size: 24px;
      color: rgba($color: #cccccc, $alpha: 1);
    }
  }
}

.termConditions {
  display: flex;
  align-items: center;
  margin-top: -10px;
  p {
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    font-weight: 400;
    color: #ffffff;
    font-size: 18px;
  }
  span {
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    font-weight: 400;
    color: #00ccff;
    font-size: 18px;
  }
}

.btnCheckBox {
  margin-right: 10px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.btnCheckBoxOutline {
  stroke: rgba($color: #00ccff, $alpha: 1.0);
  stroke-width: 2px;
}

.btnCheckBoxCenterActive {
  fill: rgba($color: #00ccff, $alpha: 1.0);
}

.subscribeBtn {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 205px;
  height: 50px;
  background: #00ccff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
  z-index: 4;
}


@media screen and (max-width: 768px) {
  .main {
    min-height: 100vh;
    height: fit-content;
    position: absolute;

  }

  .wrap {
    min-height: 100vh;
    height: fit-content;
  }

  .mainBackground {
    background-position: center;
  }

  .contactText {
    padding: 20px 10px 0;
    box-sizing: border-box;
    p {
    font-size: 22px;
    }
   b {
    font-size: 22px;
   }
  }

  .container {
    width: 100%;
  } 

  
.inputWrap {
  margin-top: 20px;
  .inputContainer {
    width: 100%;
    height: 97px;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    box-sizing: border-box;
    margin-top: 20px;
    span {
      font-family: 'Montserrat', sans-serif;
      font-style: italic;
      font-weight: 500;
      color: #00ccff;
      font-size: 20px;
      margin: 0 0 10px 15px;
    }
  }
  input {
    border-radius: 8px;
    outline: none;
    border: 1px solid rgba($color: #00ccff, $alpha: 0.5);
    background-color: rgba($color: #000000, $alpha: 0.8);
    color: rgba($color: #ffffff, $alpha: 1.0);
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    width: 100%;
    height: 45px;
    padding: 0 15px;
    box-sizing: border-box;
    &::placeholder {
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;
      font-style: italic;
      font-size: 24px;
      color: rgba($color: #cccccc, $alpha: 1);
    }
  }
}
}